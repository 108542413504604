var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    attrs: {
      "id": "form-package-config-modal",
      "size": "xl",
      "centered": true,
      "no-close-on-backdrop": true,
      "no-close-on-esc": true,
      "hide-footer": true,
      "title": _vm.$t("packageConfig.form.".concat(_vm.data.id ? "update" : "create"))
    },
    on: {
      "show": _vm.onShow,
      "hide": _vm.onHide
    }
  }, [_c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit,
          invalid = _ref.invalid,
          pristine = _ref.pristine;
        return [_c('IAmOverlay', {
          attrs: {
            "loading": _vm.loading
          }
        }, [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return handleSubmit(_vm.save);
            }
          }
        }, [_c('b-row', {
          attrs: {
            "align-horizontally": "start"
          }
        }, [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('packageConfig.form.name'),
            "vid": "name",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "name"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('packageConfig.form.name')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("*")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "name",
                  "state": _vm.getValidationState(validationContext),
                  "placeholder": _vm.$t('packageConfig.form.name'),
                  "disabled": false
                },
                model: {
                  value: _vm.data.name,
                  callback: function callback($$v) {
                    _vm.$set(_vm.data, "name", $$v);
                  },
                  expression: "data.name"
                }
              }), validationContext.errors.length ? _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('packageConfig.form.price'),
            "vid": "price",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "price"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('packageConfig.form.price')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("*")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('IAmInputMoney', {
                directives: [{
                  name: "remove-non-numeric-chars",
                  rawName: "v-remove-non-numeric-chars.allNumber",
                  modifiers: {
                    "allNumber": true
                  }
                }],
                attrs: {
                  "id": "price",
                  "value-money": _vm.data.price,
                  "state": _vm.getValidationState(validationContext),
                  "placeholder": _vm.$t('packageConfig.form.price'),
                  "disabled": false,
                  "require": true
                },
                on: {
                  "update:valueMoney": function updateValueMoney($event) {
                    return _vm.$set(_vm.data, "price", $event);
                  },
                  "update:value-money": function updateValueMoney($event) {
                    return _vm.$set(_vm.data, "price", $event);
                  }
                },
                model: {
                  value: _vm.data.price,
                  callback: function callback($$v) {
                    _vm.$set(_vm.data, "price", $$v);
                  },
                  expression: "data.price"
                }
              }), validationContext.errors.length ? _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('packageConfig.form.unit'),
            "vid": "unit",
            "rules": "required|required|min:1"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "unit",
                  "state": _vm.getValidationState(validationContext)
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('packageConfig.form.unit')) + " "), true ? _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("*")]) : _vm._e()];
                  },
                  proxy: true
                }], null, true)
              }, [_c('v-select', {
                attrs: {
                  "id": "unit",
                  "options": _vm.units,
                  "clearable": false,
                  "placeholder": _vm.$t('packageConfig.form.unit')
                },
                scopedSlots: _vm._u([{
                  key: "option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$t("packageConfig.units.".concat(data.label))) + " ")])];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$t("packageConfig.units.".concat(data.label))) + " ")])];
                  }
                }, {
                  key: "no-options",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.data.unit,
                  callback: function callback($$v) {
                    _vm.$set(_vm.data, "unit", $$v);
                  },
                  expression: "data.unit"
                }
              }), _c('b-form-invalid-feedback', {
                attrs: {
                  "state": _vm.getValidationState(validationContext)
                }
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "searchLimit"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('packageConfig.form.searchLimit')) + " ")];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          directives: [{
            name: "remove-non-numeric-chars",
            rawName: "v-remove-non-numeric-chars.allNumber",
            modifiers: {
              "allNumber": true
            }
          }],
          attrs: {
            "id": "searchLimit",
            "name": 'search',
            "placeholder": _vm.$t('packageConfig.form.searchLimit'),
            "number": "",
            "disabled": false,
            "required": false,
            "state": null
          },
          model: {
            value: _vm.data.searchLimit,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "searchLimit", $$v);
            },
            expression: "data.searchLimit"
          }
        })], 1)], 1), _vm.data.type !== 'INVOICE' ? _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('packageConfig.form.bookingLimit'),
            "vid": "bookingLimite",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "bookingLimite"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('packageConfig.form.bookingLimit')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("*")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                directives: [{
                  name: "remove-non-numeric-chars",
                  rawName: "v-remove-non-numeric-chars.allNumber",
                  modifiers: {
                    "allNumber": true
                  }
                }],
                attrs: {
                  "id": "bookingLimite",
                  "number": "",
                  "state": _vm.getValidationState(validationContext),
                  "placeholder": _vm.$t('packageConfig.form.bookingLimit'),
                  "disabled": false
                },
                model: {
                  value: _vm.data.bookingLimit,
                  callback: function callback($$v) {
                    _vm.$set(_vm.data, "bookingLimit", $$v);
                  },
                  expression: "data.bookingLimit"
                }
              }), validationContext.errors.length ? _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1) : _vm._e(), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('packageConfig.form.issueLimit'),
            "vid": "issueLimit",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "issueLimit"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('packageConfig.form.issueLimit')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("*")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                directives: [{
                  name: "remove-non-numeric-chars",
                  rawName: "v-remove-non-numeric-chars.allNumber",
                  modifiers: {
                    "allNumber": true
                  }
                }],
                attrs: {
                  "id": "issueLimit",
                  "name": 'issueLimit',
                  "number": "",
                  "state": _vm.getValidationState(validationContext),
                  "placeholder": _vm.$t('packageConfig.form.issueLimit'),
                  "disabled": false
                },
                model: {
                  value: _vm.data.issueLimit,
                  callback: function callback($$v) {
                    _vm.$set(_vm.data, "issueLimit", $$v);
                  },
                  expression: "data.issueLimit"
                }
              }), validationContext.errors.length ? _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _vm.data.type !== 'INVOICE' ? _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('packageConfig.form.paxLimit'),
            "vid": "paxLimit",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "paxLimit"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('packageConfig.form.paxLimit')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("*")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                directives: [{
                  name: "remove-non-numeric-chars",
                  rawName: "v-remove-non-numeric-chars.allNumber",
                  modifiers: {
                    "allNumber": true
                  }
                }],
                attrs: {
                  "id": "paxLimit",
                  "name": 'paxLimit',
                  "state": _vm.getValidationState(validationContext),
                  "number": "",
                  "placeholder": _vm.$t('packageConfig.form.paxLimit'),
                  "disabled": false
                },
                model: {
                  value: _vm.data.paxLimit,
                  callback: function callback($$v) {
                    _vm.$set(_vm.data, "paxLimit", $$v);
                  },
                  expression: "data.paxLimit"
                }
              }), validationContext.errors.length ? _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1) : _vm._e(), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('packageConfig.form.feeTransaction'),
            "vid": "feeTransaction",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "feeTransaction"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('packageConfig.form.feeTransaction')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("*")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                directives: [{
                  name: "remove-non-numeric-chars",
                  rawName: "v-remove-non-numeric-chars.allNumber",
                  modifiers: {
                    "allNumber": true
                  }
                }],
                attrs: {
                  "id": "feeTransaction",
                  "name": 'feeTransaction',
                  "state": _vm.getValidationState(validationContext),
                  "number": "",
                  "placeholder": _vm.$t('packageConfig.form.feeTransaction'),
                  "disabled": false
                },
                model: {
                  value: _vm.data.feeTransaction,
                  callback: function callback($$v) {
                    _vm.$set(_vm.data, "feeTransaction", $$v);
                  },
                  expression: "data.feeTransaction"
                }
              }), validationContext.errors.length ? _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "exclusiveAgencies"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('packageConfig.form.exclusiveAgencies')) + " ")];
            },
            proxy: true
          }], null, true)
        }, [_c('AgencySelect', {
          attrs: {
            "label": 'exclusiveAgencies',
            "placeholder": _vm.$t('packageConfig.form.exclusiveAgencies'),
            "value": _vm.data.exclusiveAgencies,
            "disabled": false
          },
          on: {
            "updateValue": function updateValue(val) {
              _vm.data.exclusiveAgencies = _vm.cloneDeep(val);
            }
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "paymentMethods"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('packageConfig.form.paymentMethods')) + " "), true ? _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("*")]) : _vm._e()];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          attrs: {
            "id": "paymentMethods",
            "taggable": "",
            "multiple": "",
            "closeOnSelect": false,
            "options": ['CREDIT', 'MONEY'],
            "placeholder": _vm.$t('packageConfig.form.paymentMethods')
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(_ref2) {
              var label = _ref2.label;
              return [_c('span', [_vm._v(" " + _vm._s(_vm.$t("packageConfig.".concat(label))) + " ")])];
            }
          }, {
            key: "selected-option",
            fn: function fn(_ref3) {
              var label = _ref3.label;
              return [_c('span', [_vm._v(" " + _vm._s(_vm.$t("packageConfig.".concat(label))) + " ")])];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.data.paymentMethods,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "paymentMethods", $$v);
            },
            expression: "data.paymentMethods"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "type"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('packageConfig.form.type')) + " "), true ? _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("*")]) : _vm._e()];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          attrs: {
            "id": "type",
            "clearable": false,
            "options": ['WEB', 'API', 'INVOICE'],
            "placeholder": _vm.$t('packageConfig.form.type')
          },
          scopedSlots: _vm._u([{
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.data.type,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "type", $$v);
            },
            expression: "data.type"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "offset": "0",
            "order": "end"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "6",
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "enableQR"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('packageConfig.form.enableQR')) + " ")];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-checkbox', {
          attrs: {
            "value": _vm.data.enableQR,
            "switch": true
          },
          model: {
            value: _vm.data.enableQR,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "enableQR", $$v);
            },
            expression: "data.enableQR"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "6",
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "enableFilter"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('packageConfig.form.enableFilter')) + " ")];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-checkbox', {
          attrs: {
            "value": _vm.data.enableFilter,
            "switch": true
          },
          model: {
            value: _vm.data.enableFilter,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "enableFilter", $$v);
            },
            expression: "data.enableFilter"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "6",
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "isGift"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('packageConfig.form.isGift')) + " ")];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-checkbox', {
          attrs: {
            "value": _vm.data.isGift,
            "switch": true
          },
          model: {
            value: _vm.data.isGift,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "isGift", $$v);
            },
            expression: "data.isGift"
          }
        })], 1)], 1)], 1)], 1)], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('div', {
          staticClass: "p-0"
        }, [_c('div', {
          staticClass: "p-0 pb-1 d-flex justify-content-between align-items-center"
        }, [_c('label', {
          attrs: {
            "for": "descriptions"
          }
        }, [_vm._v(_vm._s(_vm.$t('packageConfig.form.descriptions')))]), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-primary.window",
            value: _vm.$t('packageConfig.btn.addDescription'),
            expression: "$t('packageConfig.btn.addDescription')",
            modifiers: {
              "hover": true,
              "v-primary": true,
              "window": true
            }
          }, {
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "d-inline-flex align-items-center gap-2",
          attrs: {
            "id": "btnAddDescription",
            "variant": "outline-primary",
            "size": "sm"
          },
          on: {
            "click": function click() {
              _vm.data.descriptions.push({
                description: null,
                value: false,
                key: null
              });
            }
          }
        }, [_c('span', {
          staticClass: "text-nowrap d-flex-center gap-1"
        }, [_c('IAmIcon', {
          attrs: {
            "icon": "plusCircleOutline",
            "size": "18"
          }
        }), _vm._v(" " + _vm._s(_vm.$t('packageConfig.btn.addDescription')) + " ")], 1)])], 1), _c('div', {
          staticClass: "p-0",
          staticStyle: {
            "max-height": "70vh",
            "overflow-y": "scroll"
          }
        }, _vm._l(_vm.data.descriptions, function (des, ind) {
          return _c('b-row', {
            key: ind
          }, [_c('b-col', {
            attrs: {
              "md": "4"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label-for": "descriptionKey_".concat(ind)
            },
            scopedSlots: _vm._u([{
              key: "label",
              fn: function fn() {
                return [_vm._v(" " + _vm._s(_vm.$t('packageConfig.form.key')) + " ")];
              },
              proxy: true
            }], null, true)
          }, [_c('b-form-input', {
            staticStyle: {
              "font-family": "monospace"
            },
            attrs: {
              "id": "descriptionKey_".concat(ind),
              "placeholder": _vm.$t('packageConfig.form.key')
            },
            model: {
              value: des.key,
              callback: function callback($$v) {
                _vm.$set(des, "key", $$v);
              },
              expression: "des.key"
            }
          })], 1)], 1), _c('b-col', {
            attrs: {
              "md": "6"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label-for": "description".concat(ind)
            },
            scopedSlots: _vm._u([{
              key: "label",
              fn: function fn() {
                return [_vm._v(" " + _vm._s(_vm.$t('packageConfig.form.description')) + " ")];
              },
              proxy: true
            }], null, true)
          }, [_c('b-form-input', {
            attrs: {
              "id": "description".concat(ind),
              "placeholder": _vm.$t('packageConfig.form.description')
            },
            model: {
              value: des.description,
              callback: function callback($$v) {
                _vm.$set(des, "description", $$v);
              },
              expression: "des.description"
            }
          })], 1)], 1), _c('b-col', {
            attrs: {
              "md": "1"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label-for": "value_".concat(ind)
            },
            scopedSlots: _vm._u([{
              key: "label",
              fn: function fn() {
                return [_vm._v(" " + _vm._s(_vm.$t('packageConfig.form.value')) + " ")];
              },
              proxy: true
            }], null, true)
          }, [_c('div', {
            staticClass: "d-flex gap-1 justify-content-around"
          }, [_c('b-form-checkbox', {
            staticClass: "py-50 px-75 rounded",
            attrs: {
              "id": "value_".concat(ind)
            },
            model: {
              value: des.value,
              callback: function callback($$v) {
                _vm.$set(des, "value", $$v);
              },
              expression: "des.value"
            }
          })], 1)])], 1), _c('b-col', {
            attrs: {
              "md": "1"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label-for": "deleteDesciption_".concat(ind)
            },
            scopedSlots: _vm._u([{
              key: "label",
              fn: function fn() {
                return [_vm._v(" " + _vm._s(_vm.$t('action')) + " ")];
              },
              proxy: true
            }], null, true)
          }, [_c('b-button', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover.v-danger.window",
              value: _vm.$t('delete'),
              expression: "$t('delete')",
              modifiers: {
                "hover": true,
                "v-danger": true,
                "window": true
              }
            }],
            staticClass: "py-50 px-75 rounded",
            attrs: {
              "id": "deleteDesciption_".concat(ind),
              "variant": "flat-danger",
              "size": "sm",
              "disabled": false
            },
            on: {
              "click": function click() {
                _vm.data.descriptions.splice(ind, 1);
              }
            }
          }, [_c('IAmIcon', {
            attrs: {
              "icon": "trash",
              "size": "18"
            }
          })], 1)], 1)], 1)], 1);
        }), 1)])])], 1), _c('div', {
          staticClass: "pb-0"
        }, [_c('div', {
          staticClass: "d-flex gap-2 justify-content-center"
        }, [_c('b-button', {
          attrs: {
            "variant": "secondary",
            "block": _vm.$store.getters['app/currentBreakPoint'] === 'xs'
          },
          on: {
            "click": function click() {
              _vm.$bvModal.hide('form-package-config-modal');
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('back')) + " ")]), _c('b-button', {
          attrs: {
            "variant": "info",
            "block": _vm.$store.getters['app/currentBreakPoint'] === 'xs',
            "disabled": invalid,
            "type": "submit"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('save')) + " ")])], 1)])], 1)], 1)];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }